import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { withRouter } from 'react-router';
import StickyFooter from './StickyFooter';
import Notification from './Notification';
import SideMenu from './SideMenu';
import ModularHeader from './ModularHeader';
import { logout } from '../services/AccountServices';
import { result } from 'lodash';
const Page = ({children, errors, successes, location, history ,internal=true, headerObj, clear=false, noScroll=false}) => {


  const [idleTime, setIdleTime] = useState(0);
	const role = cookie.load('role') ? cookie.load('role') : 'None';
  const user = cookie.load('user') ? cookie.load('user') : null;
  const [success, setSuccess] = useState(null);


  useEffect(()=>{
	if(role!==null&&(role==="Insurer admin"||role=== "Nurse Case Manager Admin"||role=== "Employer admin"||role==="TPA admin"||role==="Utilization reviewer admin")&&!(location.pathname.includes("reportsExternal")||location.pathname.includes("report")||location.pathname.includes("reports")))
	{
		history.push('/reportsExternal');

	}
		
  },[role])

  useEffect(()=>{
	setSuccess(null);
		
  },[successes])

  useEffect(() => {
	if(location.pathname.includes("account-confirm-email"))
	{
			cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
		localStorage.clear();
		return;
	}
	if(user===null)
	{
		if(	cookie.load('user')===undefined&&cookie.load('token')===undefined  ){
		if(location.pathname!=='/login'&&!location.pathname.includes("/password/reset")&&!location.pathname.includes("/account-confirm-email/")){
			console.log("cookie timed out");

			handleLogout();
		}
	}
	}
}, [user])


useEffect(() => {


	if(!location.pathname.includes("patient"))
	{
		localStorage.removeItem("patient");
	}


	var result = localStorage.getItem("success")
    if(result===null||result===undefined)
    {
         return;      
    }
	else
	{
		setSuccess(result);
		localStorage.removeItem("success");

	}
	
}, [])

  useEffect(() => {

		if(headerObj!==undefined&& headerObj.body!==undefined && headerObj.body.name===undefined&&user)
		{
			headerObj.body.name= user.first_name+" "+user.last_name 
			console.log(headerObj)
		}
	}, [])

	useEffect(() => {
		window.onload = resetTimer;
		document.onmousemove = resetTimer;
		document.onload = resetTimer;
		document.onmousedown = resetTimer; 
		document.ontouchstart = resetTimer;
		document.onclick = resetTimer;    
		document.onkeydown = resetTimer;   
		document.addEventListener('scroll', resetTimer, true);
		return updateTimer();
	}, [idleTime])

	const isEditPageCheck=()=>{
		var parts = location.pathname.split("/")
		return parts[1]==="outcomes";

	}

	const updateTimer = () => {
		const timer = setTimeout(() => {
		//	console.log(idleTime)
			if (idleTime > 3600) {
				console.log("timed out");
				handleLogout();
			}
			else {
				setIdleTime(idleTime + 1);
			}
		}, 1000);

		return () => clearTimeout(timer);
	}

	const resetTimer = () => {
		setIdleTime(0);
	}

	const handleLogout = (event) => {
		if(isEditPageCheck()){return;}
		logout(onLogout);
		
	}

	const onLogout = () => {
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
	localStorage.clear();
		history.push('/login');
	}
	const GetSuccesses=()=>{
		result="";
		if(successes!==undefined&&successes!==null){
			result+=successes
		}
		
		if(success!==null)
			{
				if(result.length>0){result+="\n"}
				result+=success
				
				

			}
		if(result===""){result=null}
		return result;
	}
  return (
    <React.Fragment>
		<div className={`w-full h-screen relative m-0 bg-white overflow-auto flex-1 	`}>
    {internal?<SideMenu onLogout={handleLogout} role={role!==null?role:"None"} name={user!==null?user.first_name+" "+user.last_name:"user"}/>:<span/>}

    <header class="absolute top-0 border-transparent  shadow-none	p-0	 m-0">
    <ModularHeader errors={errors} successes= {GetSuccesses()} key={headerObj} internal={internal} headerObj={headerObj} className="shadow-none	">
    <Notification errors={errors} successes= {GetSuccesses()}  internal={internal} headerObj={headerObj} key={errors} clear={clear}/>
    </ModularHeader>


  </header>
  <div id="child container" className={internal?`w-[calc(100%-196px)] ${noScroll?"overflow-hidden":"overflow-auto"}	float-right z-0 m-0 h-full	flex flex-col shadow-none		 relative bg-white`:`w-full m-auto	z-0 h-full relative bg-white`}>
          {children}
          <div className='grow shrink bg-white'/>
          <StickyFooter internal={internal}/>


  </div>




    </div>

         


    </React.Fragment>
    
    
    
    
  )
}

export default withRouter(Page);
