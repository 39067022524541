import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import moment from 'moment-timezone';
import { getTimezone } from '../utility/UserUtility';
import { TailSpin	 } from  'react-loader-spinner'





const Downloader = ({history}) => { 
	const timezone = getTimezone();
	const key = useParams().key;
    const [errors, setErrors] = useState(null);
    const [downloaded, setDownloaded] = useState(false);


	useEffect(() => {
		if(key&&!downloaded ){
            var result = localStorage.getItem("pdf")
            if(result===null||result===undefined)
            {
               // window.close();
            }
            localStorage.removeItem("pdf");
            
            const data = JSON.parse(result);
            if(key!==data.id)
            {
                //window.close();
 
            }


            download(data);
		}
	}, [])



	const DateDisplay=(day)=>{
		var d = moment(day).tz(timezone);
		return d.format("MM-DD-YYYY");
		

	}
	

	const download=(data)=>{
        console.log(data)
        var name= data.patient_name.split(' ').join('_')+(data.is_post_program_checkin!==undefined&&data.is_post_program_checkin===true?"_6_Months_Post_":"_Week_"+data.included_weeks+"_")+DateDisplay(data.prepared_date)+".pdf"

        var pdf = data.report_pdf_data;
        var a=document.createElement('a');
        a.download=name;
        a.href=pdf;
        a.click();
        setDownloaded(true);
      //  window.close()

    }

       

        

    return (
		<Page internal={false} >
           
    <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
            {downloaded===false?<React.Fragment><TailSpin	
                        height="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    />
                    <br /> <br />
                <h4 className="font-['Inter']">Downloading file...</h4>
                </React.Fragment>	:
                <React.Fragment>
        <h3 className="font-['Inter'] text-lg">Download Complete</h3>
        <h4 className="font-['Inter']">Once the file is saved, You can close this tab</h4>
        </React.Fragment>}
	</div>	
				
		</Page>
     
    );

}

export default withRouter(Downloader);
