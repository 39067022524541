import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import moment from 'moment-timezone';
import { getTimezone } from '../utility/UserUtility';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import API from '../services/API';
import { TailSpin	 } from  'react-loader-spinner'




const DownloadZip = ({history}) => { 
	const timezone = getTimezone();
	const key = useParams().key;
    const [errors, setErrors] = useState(null);
    const [downloaded, setDownloaded] = useState(false);


	useEffect(() => {
		if(key){
            
				
		
					API.get(`outcome_reports/?patient_id=${key}&&pdf_data=${true}` ).then((response) => {
		
						console.log(response.data)
						var data =response.data;
						
		
		
							download(data,data.results[0].patient_name.split(' ').join('_'));
		
				
					
					}).catch(error => {
					console.log(error)
					window.close();
		
					
					})
		
				


		
	}
	else
	{
		window.close();

	}
	}, [])



	const DateDisplay=(day)=>{
		var d = moment(day).tz(timezone);
		return d.format("MM-DD-YYYY");
		

	}
	

	const download=(data,patientname)=>{
        
        var obj= Object.entries( data.results);
				var zip = new JSZip();
				var pdf = zip.folder("pdf");

				obj.forEach(element=>{
					var e= element[1];
					console.log(e);

					if(e.report_pdf_data!==undefined)
					{
						var uri = e.report_pdf_data;
						var idx = uri.indexOf('base64,') + 'base64,'.length; // or = 28 if you're sure about the prefix
						var content = uri.substring(idx);
						pdf.file(`${patientname}`+(e.is_post_program_checkin!==undefined&&e.is_post_program_checkin===true?`_6_Month_Post`:`_Week_${e.included_weeks}`)+`_Outcomes_${DateDisplay( e.prepared_date)}.pdf`, content, {base64: true});

					}

				})
				zip.generateAsync({type:"Blob"}).then(function (content) {
					saveAs(content, patientname+"_"+DateDisplay(new Date())+".zip");
                    //window.close()
					setDownloaded(true)


			   });

    }

       

        

    return (
		<Page internal={false} >
           <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
			{!downloaded?<React.Fragment>
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
		  <h4 className="font-['Inter']">Downloading zip of all files...</h4>
		  </React.Fragment>:
		   <React.Fragment>
		   <h3 className="font-['Inter'] text-lg">Download Complete</h3>
		   <h4 className="font-['Inter']">Once the file is saved, You can close this tab</h4>
		   </React.Fragment>
			}
	</div>
				
				
		</Page>
     
    );

}

export default withRouter(DownloadZip);
